<template>
  <div class="home-container home-container1">
    <Header></Header>
    <div class="container">
      <div class="first pr">
        <img src="../assets/img/home/home-banner.jpg" alt="" class="w-100c" />
        <div class="pa-center color-white text-center">
          <h1 class="fs-64">A well-known freight forwarder</h1>
          <h2 class="fs-38 mt-40">
            Shanghai Lead Speed International Logistics Co.,LTD.
          </h2>
        </div>
      </div>
      <div class="second ptb-100 bg-white">
        <h2 class="fs-48 text-center pr title-bottom-border pb-15">About US</h2>
        <div class="df">
          <div class="bg-f8f8f8">
            <p class="fs-20">
              <b class="fs-20"
                >Shanghai LeadSpeed International Logistic Co.，LTD</b
              >
              is a professional, dynamically driven forwarding Company locate in
              Shanghai, China.
            </p>
            <p class="fs-20">
              We have grown to become a significant force among independent
              freight forwarders and our goal is to provide competitive shipping
              and air services from all main Chinese ports to all over the
              world. We tailor to the needs of our customers while maintaining
              one of the highest levels of customer service in the industry. We
              want to make every aspect of shipping your cargo as easy as
              possible.
            </p>
            <p class="fs-16">
              <el-button
                ><router-link
                  class="nav-link custom-nav-link"
                  :to="{ name: 'About' }"
                  >LEARN MORE</router-link
                ></el-button
              >
            </p>
          </div>
          <div class="pr df-ai-center">
            <img src="../assets/img/home/about2.png" alt="" />
            <img src="../assets/img/home/about.png" alt="" class="pa" />
          </div>
        </div>
      </div>
      <div class="third ptb-100 bg-f2f2f2">
        <h2 class="fs-48 text-center pr title-bottom-border pb-15">
          Core Business
        </h2>
        <ul class="df">
          <li>
            <img src="../assets/img/home/cb1.jpg" alt="" />
            <p>
              <router-link
                class="nav-link custom-nav-link"
                :to="{ name: 'Solution' }"
                >Marine transport business</router-link
              >
            </p>
          </li>
          <li>
            <img src="../assets/img/home/cb2.jpg" alt="" />
            <p>
              <router-link
                class="nav-link custom-nav-link"
                :to="{ name: 'Solution' }"
                >Air freight business</router-link
              >
            </p>
          </li>
          <li>
            <img src="../assets/img/home/cb3.jpg" alt="" />
            <p>
              <router-link
                class="nav-link custom-nav-link"
                :to="{ name: 'Solution' }"
                >Storage</router-link
              >
            </p>
          </li>
          <li>
            <img src="../assets/img/home/cb4.jpg" alt="" />
            <p>
              <router-link
                class="nav-link custom-nav-link"
                :to="{ name: 'Solution' }"
                >Electricity package</router-link
              >
            </p>
          </li>
          <li>
            <img src="../assets/img/home/cb5.jpg" alt="" />
            <p>
              <router-link
                class="nav-link custom-nav-link"
                :to="{ name: 'Solution' }"
                >International Express line</router-link
              >
            </p>
          </li>
        </ul>
      </div>
      <div class="fourth ptb-100">
        <h2 class="fs-48 text-center pr title-bottom-border pb-15">
          Contact US
        </h2>
        <div class="div-tabs">
          <el-tabs
            v-model="activeName"
            type="card"
            class="demo-tabs"
            @tab-click="handleClick"
          >
            <el-tab-pane name="first">
              <ul class="df">
                <li>
                  <div>
                    <p class="text-center"><b class="fs-18">Phone:</b></p>
                    <p class="text-center mt-10">
                      <a href="tel:008618021052066" class="fs-20">008618021052066</a>
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <p class="text-center"><b class="fs-18">Email:</b></p>
                    <p class="text-center mt-10">
                      <a href="mailto:sales01@leadspeedintl.com" class="fs-20"
                        >sales01@leadspeedintl.com</a
                      >
                    </p>
                  </div>
                </li>
              </ul>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../components/header";
import Footer from "../components/footer";

export default {
  name: "Home_",
  data() {
    return {
      activeName: "first",
    };
  },
  components: {
    Header,
    Footer,
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
.home-container1 .header .fr > ul {
  li.el-menu-item:nth-child(2),
  li li:nth-child(1) {
    background-color: #f47a2c;
    a {
      color: white;
    }
  }
}
</style>
